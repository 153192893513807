import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'
import React from 'react'

import { Main } from '@leshen/ui'

import {
  mapRichTextHero,
  mapSections,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

import SpanishDoNotSellForm from '../components/SpanishDoNotSellForm'

const SpanishDoNotSell = ({ data }) => {
  const {
    contentfulPage: { sections, hero, spanish },
  } = data

  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
    spanish,
  }

  const sectionOptions = {
    spanish,
  }

  return (
    <Layout
      data={data}
      main={
        <Main>
          {/* Pass the hero to Leshens map so it behaves like normal when coming from Contentful */}
          {mapSections(hero, heroOptions)}
          <div id="loadingScrollTarget" />
          {/* AvailablePlans will show the package cards based on what is available from the Availability form getting used from the Hero. */}
          <SpanishDoNotSellForm />
          {/* Render the rest of the sections from Contentful like normal */}
          {sections &&
            sections.map((section) => mapSections(section, sectionOptions))}
        </Main>
      }
    />
  )
}

export default SpanishDoNotSell

export const query = graphql`
  query SpanishDoNotSell($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
